.react-select {
  width: 100%;

  .react-select__control {
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
    height: calc(1.5em + 1.65rem + 2px);
    border-radius: 0.42rem;
    width: 100%;
    font-weight: 400;
    border: 1px solid #e4e6ef;
  }

  .react-select__value-container {
    padding: 0 1.42rem;
    line-height: 1.5;
    font-size: 1.08rem;
  }

  .react-select__placeholder {
    white-space: nowrap;
  }
}